// import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

// /*
//  * This component is built using `gatsby-image` to automatically serve optimized
//  * images with lazy loading and reduced file sizes. The image is loaded using a
//  * `useStaticQuery`, which allows us to load the image from directly within this
//  * component, rather than having to pass the image data down from pages.
//  *
//  * For more information, see the docs:
//  * - `gatsby-image`: https://gatsby.dev/gatsby-image
//  * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
//  */

// const Image = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "kagami_web1_header.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 1000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

// export default Image

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

//ファイルパスをクエリで全件取得
const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }

      const imageSizes = image.node.childImageSharp.sizes;
      return (
        <Img
          alt={props.alt}
          sizes={imageSizes}
        />
      );
    }}
  />
)
export default Image

// // 画像ファイルパスをプロパティに取るようなコンポーネントを定義
// export default ({ filename }) => (

//   // ページじゃないコンポーネントでもGraphQLが使えるように
//   // StaticQueryタグを使う
//   <StaticQuery

//     // GraphQLのクエリ引数には何も指定しない！
//     query={graphql`
//       query {
//         images: allFile {
//           edges {
//             node {
//               relativePath
//               name
//               childImageSharp {
//                 sizes(maxWidth: 1000) {
//                   ...GatsbyImageSharpSizes
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}

//     // 全画像情報がdataに代入されている
//     render={(data) => {

//       // 指定した画像ファイルパス（コンポーネントのプロパティ）と
//       // 一致するgatsby-image用の情報を取得
//       const image = data.images.edges.find(n => {
//         return n.node.relativePath.includes(filename)
//       })

//       if (!image) return
      
//       // Imgタグでgatsby-imageで最適化された画像を表示する
//       const imageSizes = image.node.childImageSharp.sizes
//       return <Img　sizes={imageSizes} />
//     }}
//   />
// )

// export default Image