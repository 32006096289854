import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `maroon`,
      marginBottom: `0.45rem`,
      //marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 100,//960,
        //padding: `1.45rem 1.0875rem`,
        padding: `0.45rem,1.0875rem`,
      }}
    >
      {<Image filename="logo.png" alt="鏡.Web Vol.1" />}
      </div>
      <div
        style={{
          margin: `0,auto`,
          maxWidth: 960,
          padding: `0.45rem,1.085rem`,
        }}
      >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `#FFFFF0`,
            textDecoration: `none`,
            margin: `0,auto`,
            padding: `0.45rem,1.085rem`,
            textAlign: `center`,
          }}
        >
          
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
